import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { QueryStatus } from '@reduxjs/toolkit/dist/query';
import { useEffect } from 'react';
import styled from '@emotion/styled';
import Sidebar from '@shared/ui/sidebars/Sidebar';
import { ContainerColumn, NarrowSection } from '@src/components/styled';
import { useLazyGetProfilePortfoliosQuery } from '@store/slices/portfolio/api';
import { ExchangeSidebar, openSidebar, selectPortfolio, } from '@store/slices/exchange';
import { GlobalSidebarElements, openSidebar as openSidebarAppAction } from '@store/slices/application';
import ConnectExchangeEmptyView from '@src/pages/Overview/components/ConnectExchangeEmptyView';
import { IsDefined } from '@src/shared/lib/Helpers';
import { useAppSelector } from '@app/store/Hooks';
import { getWalletsWithExchanges } from '@store/slices/exchange/thunks';
import useThunkDispatch from '@src/shared/lib/hooks/useThunkDispatch';
import { useGetExchangeAccountsQuery } from '@shared/api/exchange';
import OverallBalance from './OverallBalance';
import ClientOverview from './ClientOverview';
import TraderOverview from './TraderOverview';
import OverviewSidebar from './OverviewSidebar';
const Container = styled(ContainerColumn)(() => ({
    alignItems: 'center',
    gap: '36px',
}));
const NarrowContainer = styled(NarrowSection.withComponent(ContainerColumn))((props) => ({
    gap: props.theme.spacing_sizes.l,
}));
const HeaderContainer = styled(ContainerColumn)((props) => ({
    height: 'auto',
    alignItems: 'center',
    boxSizing: 'border-box',
    backgroundColor: `${props.theme.customColors.surface.surface}`,
    borderBottom: `1px solid ${props.theme.customColors.surface.outline}`,
    paddingTop: props.theme.spacing_sizes.l,
    paddingBottom: '36px',
    gap: '48px',
}));
const Overview = () => {
    const { currentUserProfile } = useAppSelector(store => store.profile);
    const { isSidebarOpen, sidebarType, walletsOverviewList, exchangesList, } = useAppSelector(store => store.exchange);
    const { user } = useAppSelector(store => store.user);
    const { status: exchangesStatus, } = useGetExchangeAccountsQuery();
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const [__, { data: portfoliosList, status: portfoliosStatus, }] = useLazyGetProfilePortfoliosQuery();
    const dispatch = useThunkDispatch();
    const handleConnectExchange = () => {
        dispatch(openSidebar(ExchangeSidebar.ConnectBinanceExchange));
    };
    const handleClosePortfolio = (portfolioId) => {
        dispatch(selectPortfolio(portfolioId));
        dispatch(openSidebar(ExchangeSidebar.ClosePortfolio));
    };
    const handleConnectBinanceExchange = () => {
        dispatch(openSidebar(ExchangeSidebar.ConnectBinanceExchange));
    };
    const handleCreatePortfolio = () => {
        dispatch(openSidebarAppAction(GlobalSidebarElements.CreatePortfolio));
        dispatch(openSidebar(ExchangeSidebar.CreatePortfolio));
    };
    useEffect(() => {
        if (walletsOverviewList.list === null && exchangesList.list === null) {
            dispatch(getWalletsWithExchanges());
        }
    }, [exchangesList, walletsOverviewList]);
    const hasWallets = walletsOverviewList.list && walletsOverviewList.list.length > 0;
    return (_jsxs(Container, { children: [_jsx(HeaderContainer, { children: _jsx(NarrowContainer, { children: _jsx(OverallBalance, { wallets: walletsOverviewList.list || [], handleExchangeConnect: handleConnectBinanceExchange }) }) }), _jsxs(NarrowContainer, { children: [(exchangesStatus !== QueryStatus.pending) &&
                        !hasWallets && (_jsx(ConnectExchangeEmptyView, { onConnectExchangeClick: handleConnectExchange })), hasWallets && (IsDefined(currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.type) &&
                        (currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.type) === 'CLIENT') && (_jsx(ClientOverview, { wallets: walletsOverviewList.list || [], exchanges: exchangesList.list || [], portfolios: portfoliosList, handleConnectExchange: handleConnectBinanceExchange, canSwitchProfile: Boolean(user === null || user === void 0 ? void 0 : user.canSwitchProfile), profileType: currentUserProfile.type })), hasWallets && (IsDefined(currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.type) &&
                        (currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.type) === 'TRADER') && (_jsx(TraderOverview, { wallets: walletsOverviewList.list || [], exchanges: exchangesList.list || [], portfolios: portfoliosList, handleClosePortfolio: handleClosePortfolio, handleCreatePortfolio: handleCreatePortfolio, canSwitchProfile: Boolean(user === null || user === void 0 ? void 0 : user.canSwitchProfile), profileType: currentUserProfile.type }))] }), _jsx(Sidebar, { isOpen: isSidebarOpen, children: _jsx(OverviewSidebar, { sidebarType: sidebarType || '' }) })] }));
};
export default Overview;
